var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "form-wrapper",
      attrs: {
        "auto-complete": "on",
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Reference" } },
        [
          _c("el-input", {
            attrs: {
              name: "ref",
              type: "text",
              "auto-complete": "off",
              disabled: "",
            },
            model: {
              value: _vm.ref,
              callback: function ($$v) {
                _vm.ref = $$v
              },
              expression: "ref",
            },
          }),
        ],
        1
      ),
      _c("h1", [_vm._v("What is the client asking for?")]),
      _c(
        "el-form-item",
        { attrs: { label: "Title", prop: "title" } },
        [
          _c("el-input", {
            ref: "title",
            attrs: { name: "title", type: "text", "auto-complete": "off" },
            model: {
              value: _vm.form.title,
              callback: function ($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Description", prop: "content" } },
        [
          _c("text-editor", {
            ref: "content_editor",
            attrs: { content: _vm.form.content },
            on: {
              "update:content": function ($event) {
                return _vm.$set(_vm.form, "content", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.another,
                callback: function ($$v) {
                  _vm.another = $$v
                },
                expression: "another",
              },
            },
            [_vm._v("Add another")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.creating },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" Create ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }